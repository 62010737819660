export const Context = {
  Home: "HOME",
  Privacy: "PRIVACY",
  Map: "MAP",
  Graph: "GRAPH",
  GraphIntra: "GRAPH_INTRA",
  Policy: "POLICY",
  Trade: "TRADE",
  Mobility: "MOBILITY",
  News: "NEWS"
}
